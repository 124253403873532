
export default {
  name: 'FormNewsletter',
  props: {
    feedback: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
      },
      isSubmitted: false,
      errors: {},
    };
  },
  methods: {
    async subscribeHandler(values) {
      try {
        await this.$api.$post(`/api/v1/newsletter/subscribe`, { ...values });

        this.isSubmitted = true;
      } catch (e) {
        this.errors = e.response?.data?.errors;
      }
    },
  },
};
